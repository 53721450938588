import form, {
  rolesForm,
  signatureAttachmentsForm,
} from "pages/settings/users/manage/schemas/form";

const {
  formField: {
    userName,
    userGroup,
    roles,
    signatureAttachments,
    preferredQuotationColumns,
    preferredSalesorderColumns,
    preferredInvoiceColumns,
    preferredPurchaseorderColumns,
  },
} = form;

const initialValues = {
  [userName.name]: "",
  [userGroup.name]: "",
  [roles.name]: [],
  [preferredQuotationColumns.name]: [],
  [preferredSalesorderColumns.name]: [],
  [preferredInvoiceColumns.name]: [],
  [preferredPurchaseorderColumns.name]: [],
  [signatureAttachments.name]: [],
};

const {
  formField: { roleName },
} = rolesForm;

const rolesInitialValues = {
  [roleName.name]: "",
};

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsInitialValues = {
  [signatureAttachmentFile.name]: "",
};

export {
  initialValues as default,
  rolesInitialValues,
  signatureAttachmentsInitialValues,
};
