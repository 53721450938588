import { useCallback, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

//import initialValues from "pages/assessments/manage/schemas/initialValues";
import validations, {
  noValidation,
} from "pages/assessments/manage/schemas/validations";
import form from "pages/assessments/manage/schemas/form";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";
import { useAppContext } from "context/AppContext";

const _ = require("lodash");
const collectionPathTrackingDailyRecords = "trackingDailyRecords";
const collectionPathTracking = "tracking";
const collectionPathUsers = "users";
const initialValues = [];
const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_DAILYRECORDS":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "NEW_DAILYRECORD_ADDED":
      return {
        isPending: false,
        data: action.payload,
        success: `New record added.`,
        error: null,
      };
    case "UPDATED_DAILYRECORD":
      return {
        isPending: false,
        data: action.payload,
        success: `Record updated.`,
        error: null,
      };
    case "DELETED_DAILYRECORD":
      return {
        isPending: false,
        data: action.payload,
        success: `Record deleted.`,
        error: null,
      };
    case "RETRIEVED_BABYPROFILE":
      return {
        isPending: false,
        data: initialValues,
        success: `Successfully retrieved baby profile.`,
        error: null,
        age: action.payload,
      };
    case "ERROR":
      return {
        isPending: false,
        data: payload,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useTrackingManager = (mode, date) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const { retrieveDoc, updateDoc, createDoc, serverTimestamp } = useDocument();
  const { pathname } = useLocation();
  const { formId, formField } = form;

  const { selectedBabyProfile } = useAppContext();
  let { babyUid, babyProfile } = selectedBabyProfile;

  const retrieveBabyUid = useCallback(async () => {
    const retrievedUser =
      user.uid && (await retrieveDoc(collectionPathUsers, user.uid));
    return retrievedUser.data.selectedBaby;
  }, [retrieveDoc, user.uid]);

  const day = parseInt(date.substring(0, 2));
  const month = parseInt(date.substring(2, 4)) - 1;
  const year = parseInt(date.substring(4, 8));
  const [calendarDate, setCalendarDate] = useState(
    new Date(year, month, day, 0, 0, 0)
  );

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "PromptAddBabyProfile",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(
    async (babyUid) => {
      try {
        dispatchIfNotUnmounted({ type: "IS_PENDING" });
        let operationInvalidError = new Error(
          "Invalid Operation. You are not allowed to carry out this activity."
        );
        operationInvalidError.name = "OperationInvalidError";

        let promptAddBabyProfile = new Error(
          "Please add a baby profile to proceed."
        );
        promptAddBabyProfile.name = "PromptAddBabyProfile";

        switch (mode) {
          case "dailylog":
            if (!pathname.includes("/tracking/dailylog")) {
              throw operationInvalidError;
            }

            if (!!babyUid || babyUid === null) {
              const retrievedUser =
                user.uid && (await retrieveDoc(collectionPathUsers, user.uid));
              babyUid = retrievedUser.data.selectedBaby;
            }

            const retrievedTrackingDoc = await retrieveDoc(
              collectionPathTracking,
              babyUid
            );

            // Get day before records
            const copyCalendarDate = _.cloneDeep(calendarDate);
            const daybefore = new Date(
              copyCalendarDate.setDate(calendarDate.getDate() - 1)
            );
            const daybeforeDate = daybefore.toLocaleDateString("en-SG", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            });
            let dailyRecords = [];
            let daybeforeRecords = [];
            if (
              retrievedTrackingDoc.data !== undefined &&
              retrievedTrackingDoc.data.records.length > 0
            ) {
              const localeDate = calendarDate.toLocaleDateString("en-SG", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              });

              let findIdx = retrievedTrackingDoc.data.records.findIndex(
                (elm) => elm.calendarDate === localeDate
              );

              if (findIdx > -1) {
                const docId =
                  retrievedTrackingDoc.data.records[findIdx].documentId;
                const retrievedTrackingDialyRecordDoc = await retrieveDoc(
                  collectionPathTrackingDailyRecords,
                  docId
                );
                dailyRecords = retrievedTrackingDialyRecordDoc.data.records;
              }

              // Get day before records
              findIdx = retrievedTrackingDoc.data.records.findIndex(
                (elm) => elm.calendarDate === daybeforeDate
              );

              if (findIdx > -1) {
                const docId =
                  retrievedTrackingDoc.data.records[findIdx].documentId;
                const daybeforeRecordDoc = await retrieveDoc(
                  collectionPathTrackingDailyRecords,
                  docId
                );
                daybeforeRecords = daybeforeRecordDoc.data.records;
              }
            }

            const data = {
              dailyRecords: dailyRecords,
              daybeforeRecords: daybeforeRecords,
              daybeforeDate: daybeforeDate,
              tocRecords: retrievedTrackingDoc?.data?.records
                ? retrievedTrackingDoc?.data?.records
                : [],
            };

            dispatchIfNotUnmounted({
              type: "INITIAL_DAILYRECORDS",
              payload: data,
            });

            break;
          default:
            throw operationInvalidError;
        }
      } catch (err) {
        dispatchError(err);
      }
    },
    [
      dispatchError,
      dispatchIfNotUnmounted,
      pathname,
      mode,
      calendarDate,
      retrieveDoc,
      user,
      //retrieveBabyUid,
    ]
  );

  useEffect(() => {
    try {
      validateOperation(babyUid);
    } catch (err) {
      dispatchError(err);
    }
    /*return () => {
      setIsUnmounted(true);
    };*/
  }, [dispatchError, validateOperation, babyUid, retrieveBabyUid]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeValidation = noValidation;
  let modeFieldDisabled = true;

  switch (mode) {
    case "dailylog":
      modeTitle = "F.O.R.M. Tracker";
      modeSubmit = "Submit";
      modeValidation = validations;
      modeFieldDisabled = false;
      break;
    case "qasurvey":
      modeTitle = "Assessment";
      modeSubmit = "Submit";
      modeValidation = validations;
      modeFieldDisabled = false;
      break;
    case "qaadvisory":
      modeTitle = "Advisory";
      modeSubmit = "Submit";
      modeValidation = noValidation;
      modeFieldDisabled = false;
      break;
    case "qascore":
      modeTitle = "Scores";
      modeSubmit = "Submit";
      modeValidation = noValidation;
      modeFieldDisabled = false;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  const submitNew = async (values, calendarDate, modeSubmit) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.SUBMIT_ASSESSMENT)) {
        if (!!babyUid || babyUid === null) {
          const retrievedUser =
            user.uid && (await retrieveDoc(collectionPathUsers, user.uid));
          babyUid = retrievedUser.data.selectedBaby;
        }

        let retrievedDoc = await retrieveDoc(
          collectionPathTracking,
          babyUid /*user.uid*/
        );

        if (retrievedDoc.data === undefined) {
          retrievedDoc = await createDoc(
            collectionPathTracking,
            {
              records: [],
            },
            user.uid, //createdBy,
            babyUid //documentId
          );
        }

        //const m_date = new Date();
        const localeDate = calendarDate.toLocaleDateString("en-SG", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        });

        // Get day before records
        const copyCalendarDate = _.cloneDeep(calendarDate);
        const daybefore = new Date(
          copyCalendarDate.setDate(calendarDate.getDate() - 1)
        );
        const daybeforeDate = daybefore.toLocaleDateString("en-SG", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        });

        let dailyRecords = [];
        let daybeforeRecords = [];
        let findIdx = -1;
        if (retrievedDoc.data.records.length > 0)
          findIdx = retrievedDoc.data.records.findIndex(
            (elm) => elm.calendarDate === localeDate
          );

        if (findIdx < 0) {
          // Create new Daily Record for Tracking function
          const createdDoc = await createDoc(
            collectionPathTrackingDailyRecords,
            {
              records: values,
              babyUid: babyUid,
              calendarDate: localeDate,
            },
            user.uid // createdBy
          );
          dailyRecords = createdDoc.data.records;

          // Update "records" variable on "tracking" collection
          const rec = {
            calendarDate: localeDate,
            documentId: createdDoc.id,
          };
          let recArray;
          retrievedDoc.data === undefined
            ? (recArray = [rec])
            : (recArray = [...retrievedDoc.data.records, rec]);
          await updateDoc(collectionPathTracking, babyUid, {
            ...retrievedDoc.data,
            records: recArray,
          });
        } else {
          // Update document "trackingDailyRecords"
          const record = retrievedDoc.data.records[findIdx];
          const updatedDoc = await updateDoc(
            collectionPathTrackingDailyRecords,
            record.documentId,
            {
              records: values,
              babyUid: babyUid,
              calendarDate: localeDate,
              modifiedAt: serverTimestamp(),
              modifiedBy: user.uid,
            }
          );
          dailyRecords = updatedDoc.data.records;

          // Get day before records
          findIdx = retrievedDoc.data.records.findIndex(
            (elm) => elm.calendarDate === daybeforeDate
          );

          if (findIdx > -1) {
            const docId = retrievedDoc.data.records[findIdx].documentId;
            const daybeforeRecordDoc = await retrieveDoc(
              collectionPathTrackingDailyRecords,
              docId
            );
            daybeforeRecords = daybeforeRecordDoc.data.records;
          }
        }

        const data = {
          dailyRecords: dailyRecords,
          daybeforeRecords: daybeforeRecords,
          daybeforeDate: daybeforeDate,
          tocRecords: retrievedDoc?.data?.records
            ? retrievedDoc?.data?.records
            : [],
        };

        const dispatchType =
          modeSubmit.current === "Submit new"
            ? "NEW_DAILYRECORD_ADDED"
            : modeSubmit.current === "Submit new"
            ? "UPDATED_DAILYRECORD"
            : "DELETED_DAILYRECORD";

        dispatchIfNotUnmounted({
          type: dispatchType,
          payload: data, //records,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    response,
    modeValidation,
    modeFieldDisabled,
    modeSubmit,
    modeTitle,
    formId,
    formField,
    submitNew,
    calendarDate,
    setCalendarDate,
    dispatchError,
    setIsUnmounted,
    retrieveBabyUid,
    babyUid,
    babyProfile,
    retrieveDoc,
  };
};
