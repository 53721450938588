import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/settings/users/manage/schemas/initialValues";
import validations from "pages/settings/users/manage/schemas/validations";

import { setRolesOfUser } from "services/authentication";

import { disableAuthUser, enableAuthUser } from "services/authentication";

const collectionPath = "users";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated the user, ${action.payload.displayName}.`,
        error: null,
      };
    case "ENABLED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully enabled the user, ${action.payload.displayName}.`,
        error: null,
      };
    case "DISABLED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully disabled the user, ${action.payload.displayName}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useSettingsManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { user } = useAuthContext();

  const { userHasPermissions } = useAbac();

  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPersistenceValue = useCallback(
    (document) => {
      try {
        if (document.signatureAttachments) {
          document.signatureAttachments = document.signatureAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        return document;
      } catch (err) {
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    (data) => {
      try {
        // nothing to convert at this moment

        return data;
      } catch (err) {
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/settings/users")) {
        throw operationInvalidError;
      }

      dispatchIfNotUnmounted({
        type: "INITIAL_USER",
        payload: initialValues,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "Manage Accounts";
  let modeSubmit = "Update";
  let modeFieldDisabled = false;
  let modePermission = Permission.VIEW_ADMIN_DASHBOARD;
  let modeValidation = validations;

  const submitEdit = async (accountId, values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (
        userHasPermissions([
          Permission.PERFORM_ADMIN_ACTION,
          Permission.UPDATE_USER,
        ])
      ) {
        values = toPersistenceValue(values);
        const updatedDoc = await updateDoc(collectionPath, accountId, {
          ...values,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        await setRolesOfUser({ roles: values.roles, userId: accountId });

        dispatchIfNotUnmounted({
          type: "UPDATED_USER",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to update user."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitEnableUser = async (uid) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (
        userHasPermissions([
          Permission.PERFORM_ADMIN_ACTION,
          Permission.UPDATE_USER,
        ])
      ) {
        const result = await enableAuthUser({ uid: uid });
        const userRecord = result.data;
        const retrievedUser = await retrieveDoc(collectionPath, userRecord.uid);
        dispatchIfNotUnmounted({
          type: "ENABLED_USER",
          payload: toPresentationValue(retrievedUser.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to enable user."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitDisableUser = async (uid) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (
        userHasPermissions([
          Permission.PERFORM_ADMIN_ACTION,
          Permission.UPDATE_USER,
        ])
      ) {
        const result = await disableAuthUser({ uid: uid });
        const userRecord = result.data;
        const retrievedUser = await retrieveDoc(collectionPath, userRecord.uid);
        dispatchIfNotUnmounted({
          type: "DISABLED_USER",
          payload: toPresentationValue(retrievedUser.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to disable user."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitEdit,
    submitEnableUser,
    submitDisableUser,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
