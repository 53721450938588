import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";

//import { signatureAttachmentsForm } from "pages/settings/users/manage/schemas/form";
//import FormAttachments from "components/molecules/Formik/FormAttachments";
//import { signatureAttachmentsInitialValues } from "pages/settings/users/manage/schemas/initialValues";
//import { signatureAttachmentsValidation } from "pages/settings/users/manage/schemas/validations";

import { roleOptions } from "pages/settings/users/manage/schemas/setups";

function BasicInformation({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { userName, userGroup, email, roles } = mainFormField;

  // destructure from Attachments Form for current section
  /*const {
    formId: signatureAttachmentsFormId,
    formField: signatureAttachmentsFormField,
  } = signatureAttachmentsForm;*/
  /*const { signatureAttachmentFile, attachmentName, attachmentURL } =
    signatureAttachmentsFormField;*/

  return (
    <Card id="basic-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Basic Information</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {userName.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={userName}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {userGroup.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={userGroup}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {email.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={email}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {roles.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormCheckboxGroup
                showTitle={false}
                form={mainForm}
                field={roles}
                options={roleOptions}
                disabled={modeDisabled}
                hiddenOptions={["MEMBER"]}
                row
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            {/*<Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {signatureAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={signatureAttachments}
                max={1}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={signatureAttachmentsFormId}
                itemInitialValues={signatureAttachmentsInitialValues}
                itemValidation={signatureAttachmentsValidation}
                itemFileField={signatureAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>*/}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BasicInformation;
