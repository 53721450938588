const Role = Object.freeze({
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  PARTICIPANT: "PARTICIPANT",
  //STUDENT: "STUDENT",
  //MEMBER: "MEMBER",
});

const Permission = Object.freeze({
  // permission of dashboard (as shown on side nav bar)
  VIEW_SUPERADMIN_DASHBOARD: "VIEW_SUPERADMIN_DASHBOARD",
  VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
  VIEW_MEMBER_DASHBOARD: "VIEW_MEMBER_DASHBOARD",

  // permission of action (as shown on side nav bar)
  VIEW_SETTINGS_ACTION: "VIEW_SETTINGS_ACTION",

  // permission for data (also use for firestore rules)
  PERFORM_SUPERADMIN_ACTION: "PERFORM_SUPERADMIN_ACTION",
  PERFORM_ADMIN_ACTION: "PERFORM_ADMIN_ACTION",

  READ_ALL_ROLES: "READ_ALL_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  READ_ROLE: "READ_ROLE",
  DELETE_ROLE: "DELETE_ROLE",

  READ_ALL_USERS: "READ_ALL_USERS",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  READ_USER: "READ_USER",
  DELETE_USER: "DELETE_USER",

  // PURGE_ROLE: "PURGE_ROLE",
  //READ_ALL_COMPANIES: "READ_ALL_COMPANIES",
  //CREATE_COMPANY: "CREATE_COMPANY",
  //UPDATE_COMPANY: "UPDATE_COMPANY",
  //READ_COMPANY: "READ_COMPANY",
  //DELETE_COMPANY: "DELETE_COMPANY",
  // PURGE_COMPANY: "PURGE_COMPANY",

  // PURGE_USER: "PURGE_USER",
  CREATE_BABY_PROFILE: "CREATE_BABY_PROFILE",
  SUBMIT_ASSESSMENT: "SUBMIT_ASSESSMENT",
});

export { Role, Permission };
