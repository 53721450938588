import * as Yup from "yup";
import form, {
  rolesForm,
  signatureAttachmentsForm,
} from "pages/settings/users/manage/schemas/form";

const {
  formField: {
    userName,
    userGroup,
    email,
    roles,
    signatureAttachments,
    preferredQuotationColumns,
    preferredSalesorderColumns,
    preferredInvoiceColumns,
    preferredPurchaseorderColumns,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [userName.name]: Yup.string()
    .required(userName.errorMsg)
    .max(200, userName.invalidMsg),
  [userGroup.name]: Yup.string()
    .required(userGroup.errorMsg)
    .max(200, userGroup.invalidMsg),
  [email.name]: Yup.string()
    .required(email.errorMsg)
    .max(200, email.invalidMsg)
    .email(email.invalidMsg),
  [roles.name]: Yup.array()
    // .min(1, roles.min1Msg)
    .max(20, roles.max20Msg),
  [signatureAttachments.name]: Yup.array().max(
    20,
    signatureAttachments.max20Msg
  ),
  [preferredQuotationColumns.name]: Yup.array(),
  [preferredSalesorderColumns.name]: Yup.array(),
  [preferredInvoiceColumns.name]: Yup.array(),
  [preferredPurchaseorderColumns.name]: Yup.array(),
});

const {
  formField: { roleName },
} = rolesForm;

const rolesValidation = Yup.object().shape({
  [roleName.name]: Yup.string()
    .nullable()
    .required(roleName.errorMsg)
    .max(200, roleName.invalidMsg),
});

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsValidation = Yup.object().shape({
  [signatureAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(signatureAttachmentFile.errorMsg)
    .test(
      "fileSize",
      signatureAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

export {
  validations as default,
  noValidation,
  rolesValidation,
  signatureAttachmentsValidation,
};
