import form, {
  rolesForm,
  signatureAttachmentsForm,
} from "pages/settings/user/new/schemas/form";

const {
  formField: { userName, userGroup, roles, emailSignOff, signatureAttachments },
} = form;

const initialValues = {
  [userName.name]: "",
  [userGroup.name]: "",
  [roles.name]: [],
  [emailSignOff]: "",
  [signatureAttachments.name]: [],
};

const {
  formField: { roleName },
} = rolesForm;

const rolesInitialValues = {
  [roleName.name]: "",
};

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsInitialValues = {
  [signatureAttachmentFile.name]: "",
};

export {
  initialValues as default,
  rolesInitialValues,
  signatureAttachmentsInitialValues,
};
