const form = {
  formId: "account-form",
  formField: {
    userName: {
      name: "userName",
      label: "User Name",
      type: "text",
      errorMsg: "User Name is required.",
      invalidMsg: "User Name is invalid.",
    },
    userGroup: {
      name: "userGroup",
      label: "User Group",
      type: "text",
      errorMsg: "User Group is required.",
      invalidMsg: "User Group is invalid.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    roles: {
      name: "roles",
      label: "Roles",
      type: "box",
      min1Msg: "Must have at least 1 Role.",
      max50Msg: "Must have at most 50 Roles.",
    },
    signatureAttachments: {
      name: "signatureAttachments",
      label: "Signature Attachment",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    preferredQuotationColumns: {
      name: "preferredQuotationColumns",
      label: "Preferred Columns",
      type: "box",
    },
    preferredSalesorderColumns: {
      name: "preferredSalesorderColumns",
      label: "Preferred Columns",
      type: "box",
    },
    preferredInvoiceColumns: {
      name: "preferredInvoiceColumns",
      label: "Preferred Columns",
      type: "box",
    },
    preferredPurchaseorderColumns: {
      name: "preferredPurchaseorderColumns",
      label: "Preferred Columns",
      type: "box",
    },
  },
};

const rolesForm = {
  formId: "roles-form",
  formField: {
    roleName: {
      name: "roleName",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
  },
};

const signatureAttachmentsForm = {
  formId: "signature-attachments-form",
  formField: {
    signatureAttachmentFile: {
      name: "signatureAttachmentsFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

export { form as default, rolesForm, signatureAttachmentsForm };
